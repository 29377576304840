<template>
<section class="signup-section">
    <div class="container my-container">
        <div class="signup-content reg">
            <div class="reg-contianer">
            <h2 class="title">Register</h2>
            <p class="user-clasification">You are registering as <span>INDIVIDUAL</span>.</p>
            <p class="steps">1 of 2</p>
            </div>

            <form :class="{'register-form': true}">
                <div class="mb-1">
                    <label for="email-user" class="col-form-label">Email</label>
                    <input v-model="form.email" type="text" placeholder="Enter your email" :class="formControl(v$.form.email)" id="email-user" required :disabled="loading || (this.request && this.request.email)">
                    <div v-if="v$.form.email.$error" class="invalid-feedback">
                        <span>{{ errorMessage(v$.form.email.$errors[0].$validator, 'Email address') }}</span>
                    </div>
                </div>
                <div class="mb-1">
                    <label for="frst-nm-user" class="col-form-label">First Name</label>
                    <input v-model="form.first_name" type="text" placeholder="Enter your first name" :class="formControl(v$.form.first_name)" id="frst-nm-user" required :disabled="loading">
                    <div v-if="v$.form.first_name.$error" class="invalid-feedback">
                        <span>{{ errorMessage(v$.form.first_name.$errors[0].$validator, 'First name') }}</span>
                    </div>
                </div>
                <div class="mb-1">
                    <label for="lst-nm-user" class="col-form-label">Last Name</label>
                    <input v-model="form.last_name" type="text" placeholder="Enter your last name" :class="formControl(v$.form.last_name)" id="lst-nm-user" required :disabled="loading">
                    <div v-if="v$.form.last_name.$error" class="invalid-feedback">
                        <span>{{ errorMessage(v$.form.last_name.$errors[0].$validator, 'Last name') }}</span>
                    </div>
                </div>
                <div class="mb-1">
                    <label for="pass-user" class="col-form-label">Password</label>
                    <input v-model="form.password" type="password" placeholder="Enter your password" :class="formControl(v$.form.password)" id="pass-user" required :disabled="loading">
                    <div v-if="v$.form.password.$error" class="invalid-feedback">
                        <span>{{ errorMessage(v$.form.password.$errors[0].$validator, 'Password') }}</span>
                    </div>
                </div>
                <div class="mb-1">
                    <label for="confirm-pass-user" class="col-form-label">Confirm Password</label>
                    <input v-model="form.confirm_password" type="password" placeholder="Re-enter your password" :class="formControl(v$.form.confirm_password)" id="confirm-pass-user" required  :disabled="loading">
                    <div v-if="v$.form.confirm_password.$error" class="invalid-feedback">
                        <span>{{ errorMessage(v$.form.confirm_password.$errors[0].$validator, 'Confirm password') }}</span>
                    </div>
                </div>
            </form>
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-next-reg" @click="next" :disabled="loading">Next</button>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapActions } from 'vuex';

export default {
    props: {
        request: {
            type: Object,
            default() {
                return {};
            }
        },
        modelValue: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['update:modelValue', 'next'],
    data() {
        return {
            loading: false,
            emailExists: false,
            form: {
                email: this.modelValue.email || (this.request && this.request.email),
                first_name: this.modelValue.first_name || (this.request && this.request.first_name),
                last_name: this.modelValue.last_name || (this.request && this.request.last_name),
                password: this.modelValue.password,
                confirm_password: this.modelValue.confirm_password
            }
        };
    },
    setup: () => ({ v$: useVuelidate() }),
    validations () {
        return {
            form: {
                email: { required, email, emailCheck: this.emailCheck },
                first_name: { required },
                last_name: { required },
                password: { required },
                confirm_password: { required, confirmPassword: this.confirmPassword }
            }
        };
    },
    methods: {
        async next() {
            this.loading = true;
            this.emailExists = false;

            await this.finduser({
                query: { email: this.form.email }
            })
            .then(result => result.total !== 0)
            .then(result => new Promise((resolve) => {
                this.v$.form.$reset();
                this.emailExists = result;
                setTimeout(() => {
                    this.v$.form.$touch();
                    resolve(result);
                }, 100);
            }));

            const isFormCorrect = await this.v$.$validate();
            if (isFormCorrect) {
                this.$emit('update:modelValue', this.form);
                this.$emit('next');
            }

            this.loading = false;
        },
        errorMessage(type, name) {
            switch(type) {
                case 'email': return `${name} is not a valid email address`;
                case 'required': return `${name} is required`;
                case 'confirmPassword': return `Invalid confirmation password`;
                case 'emailCheck': return 'Email address is already exists';
            }
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        confirmPassword(value) {
            return value === this.form.password;
        },
        emailCheck() {
            return !this.emailExists;
        },
        ...mapActions('users', { finduser: 'find' })
    }
}
</script>