<template lang="pug">
//- Step 1
step-one(v-if="step === 0 && !isLoading" @next="firstStep")

//- Individual signup step one
sign-up-step-one(v-if="step === 1 && type === 'user' && !isLoading" v-model="form" @next="step = 2" :request="request")

//- Individual signup step two
sign-up-step-two(v-if="step === 2 && type === 'user' && !isLoading" @next="individualSignup" @back="step = 1" :request="request")

//- Individual signup success
sign-up-success(v-if="step === 3 && type === 'user' && !isLoading" :email="form.email" :password="form.password")

//- Register a church
sign-up-church-step-one(v-if="step === 1 && type === 'church' && !isLoading" v-model="form" @next="churchSignup")

//- Register a church success
sign-up-church-success(v-if="step === 2 && type === 'church' && !isLoading" :email="form.email" :password="form.password")
</template>

<script>
import { computed, defineComponent, getCurrentInstance, ref, watch } from 'vue';
import { mapActions } from 'vuex';

import StepOne from '../components/joinus.components/step1.section.vue';
import SignUpStepOne from '../components/joinus.components/signup.step1.section.vue';
import SignUpStepTwo from '../components/joinus.components/signup.step2.section.vue';
import SignUpChurchStepOne from '../components/joinus.components/chruch.signup.step1.section.vue';
import SignUpChurchSuccess from '../components/joinus.components/church.signup.success.section.vue';
import SignUpSuccess from '../components/joinus.components/signup.success.section.vue';
import { useFind } from '@feathersjs/vuex/dist';

export default defineComponent({
    data() {
        return {
            username: null,
            password: null
        }
    },
    setup() {
        const vm = getCurrentInstance();
        const hashData = vm.appContext.app.config.globalProperties.$route.params.hashdata;
        const { UserInvites }  = vm.appContext.provides.$FeathersVuex.api;

        let request = computed(() => null);
        let isLoading = ref(false);
        let type = ref(null);
        let step = ref(0);
        let form = ref({});

        if(hashData) {
            const invite = useFind({ model: UserInvites, params: computed(() => {
                return { query: { status: 'Pending' } }
            }), fetchParams: computed(() => {
                return { query: { hash: hashData, status: 'Pending' } }
            }), immediate: true });
            request = computed(() => {
                if(invite.items.value.length === 0)
                    return null;
                return invite.items.value[0];
            });
            watch(request, (req) => {
                if(req) {
                    type.value = 'user';
                    step.value = 1;
                }
            });
            isLoading = invite.isPending;
        }

        return {
            type,
            step,
            form,
            request,
            isLoading
        };
    },
    methods: {
        firstStep(value) {
            this.type = value.type;
            this.step = 1;
        },
        async individualSignup(extended) {
            try {
                await this.createUser({
                    email: this.form.email,
                    password: this.form.password,
                    fname: this.form.first_name,
                    lname: this.form.last_name,
                    extended
                });
                this.step = 3;
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            }
        },
        async churchSignup() {
            try {
                const user = await this.createUser({
                    email: this.form.email,
                    password: this.form.password,
                    fname: this.form.first_name,
                    lname: this.form.last_name,
                });
                await this.createChurch({
                    owner_id: user.id,
                    website: this.form.website,
                    name: this.form.church_name
                });
                this.step = 2;
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            }
        },
        nextStepUserOne({ username, password }) {
            this.step = 2;
            this.username = username;
            this.password = password;
        },
        ...mapActions('users', { createUser: 'create' }),
        ...mapActions('church', { createChurch: 'create' })
    },
    components: {
        StepOne,
        SignUpStepOne,
        SignUpStepTwo,
        SignUpChurchStepOne,
        SignUpChurchSuccess,
        SignUpSuccess
    }
})
</script>