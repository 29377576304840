<template>
    <section class="signup-section">
        <div class="container my-container">
            <div class="signup-content">
                <h3>How would you like to register ?</h3>
                <div class="btn-group btn-select-container" role="group" aria-label="Basic radio toggle button group">
                    <input v-model="type" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked value="user">
                    <label class="btn btn-select-individual img-fluid" for="btnradio1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="61.293" height="95.334" viewBox="0 0 61.293 95.334">
                        <path id="individual" d="M79.779,43.627c-2.971-2.547-8.914-3.184-16.873-2.759-1.273.106-2.016.318-2.441.318V41.08c6.155-3.4,9.444-10.4,9.444-17.616A18.624,18.624,0,0,0,51.764,5h0a18.368,18.368,0,0,0-18.04,18.358c0,7.216,3.29,14.22,9.444,17.616v.106c-.424,0-1.167-.212-2.441-.318-7.959-.424-13.9.318-16.873,2.759C13.88,52.01,34.573,93.184,39.242,98.065a7.208,7.208,0,0,0,3.926,1.91,52.859,52.859,0,0,0,8.6.318,67.626,67.626,0,0,0,8.6-.318,6.568,6.568,0,0,0,3.926-1.91C69.061,93.29,89.754,52.116,79.779,43.627Z" transform="translate(-21.17 -5)" fill="#656565"/>
                        </svg>                          
                    </label>
                    <input  v-model="type" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" value="church">
                    <label class="btn btn-select-church" for="btnradio3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="103" height="105.091" viewBox="0 0 103 105.091">
                        <path id="church" d="M73.329,32.024V63.133l21.7,16.208v18.43H103v7.32H0v-7.32H7.843V79.341L29.671,63.133V32.024L50.324,19.476V7.451H44.049V5.228h6.274V0h2.353V5.228h6.274V7.451H52.676V19.476ZM52.676,66.662V78.034h9.411C62.088,72.283,58.689,67.447,52.676,66.662Zm0,13.725v7.058h9.411V80.387Zm0,9.542v8.1h9.411v-8.1Zm-2.484,8.1v-8.1h-9.28v8.1Zm0-10.588V80.387h-9.28v7.058Zm0-9.411V66.662c-5.882.784-9.28,5.621-9.28,11.372Zm2.484-43.527V42.35h8.1A9.292,9.292,0,0,0,52.676,34.508Zm0,10.2V53.33A9.585,9.585,0,0,0,60.911,44.7ZM50.324,53.33V44.7H42.089A9.459,9.459,0,0,0,50.324,53.33Zm0-10.98V34.508a9.432,9.432,0,0,0-8.235,7.843Z" fill="#656565" fill-rule="evenodd"/>
                        </svg>                          
                    </label>
                </div>
                <div class="d-grid gap-2">
                <button type="button" class="btn btn-next" @click="next">Next</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            type: 'user'
        };
    },
    methods: {
        next() {
            this.$emit('next', {
                type: this.type
            });
        }
    }
}
</script>