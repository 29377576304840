<template>
    <section class="signup-section">
        <div class="container my-container">
            <div class="signup-content reg-church">
                <div class="reg-contianer">
                <h2 class="title">Register</h2>
                <p class="user-clasification">You are registering as <span>Church</span>.</p>
                </div>

                <form class="register-form">
                    <div class="mb-1">
                        <label for="church-nm-user" class="col-form-label">Church Name</label>
                        <input v-model="form.church_name" type="text" placeholder="Enter your church name" :class="formControl(v$.form.church_name)" id="church-nm-user" :disabled="loading">
                        <div v-if="v$.form.church_name.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.church_name.$errors[0].$validator, 'Church name') }}</span>
                        </div>
                    </div>
                    <div class="mb-1">
                        <label for="church-website-user" class="col-form-label">Website</label>
                        <input v-model="form.website" type="text" placeholder="Enter your Website" :class="formControl(v$.form.website)" id="church-website-user" :disabled="loading" @focus="!form.website ? form.website = 'https://www.' : null">
                        <div v-if="v$.form.website.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.website.$errors[0].$validator, 'Website') }}</span>
                        </div>
                    </div>
                    <div class="mb-1">
                        <label for="email-user" class="col-form-label">Head Pastor's Email</label>
                        <input v-model="form.email" type="text" placeholder="Enter your email" class="form-control" id="email-user" :class="formControl(v$.form.email)" :disabled="loading">
                        <div v-if="v$.form.email.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.email.$errors[0].$validator, 'Head Pastor\'s Email') }}</span>
                        </div>
                    </div>
                    <div class="mb-1">
                        <label for="frst-nm-user" class="col-form-label">First Name</label>
                        <input v-model="form.first_name" type="text" placeholder="Enter your first name" class="form-control" id="frst-nm-user" :class="formControl(v$.form.first_name)" :disabled="loading">
                        <div v-if="v$.form.first_name.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.first_name.$errors[0].$validator, 'First name') }}</span>
                        </div>
                    </div>
                    <div class="mb-1">
                        <label for="lst-nm-user" class="col-form-label">Last Name</label>
                        <input v-model="form.last_name" type="text" placeholder="Enter your last name" class="form-control" id="lst-nm-user" :class="formControl(v$.form.last_name)" :disabled="loading">
                        <div v-if="v$.form.last_name.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.last_name.$errors[0].$validator, 'Last name') }}</span>
                        </div>
                    </div>
                    <div class="mb-1">
                        <label for="pass-user" class="col-form-label">Password</label>
                        <input v-model="form.password" type="password" placeholder="Enter your password" class="form-control" id="pass-user" :class="formControl(v$.form.password)" :disabled="loading">
                        <div v-if="v$.form.password.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.password.$errors[0].$validator, 'Password') }}</span>
                        </div>
                    </div>
                    <div class="mb-1">
                        <label for="confirm-pass-user" class="col-form-label">Confirm Password</label>
                        <input v-model="form.confirm_password" type="password" placeholder="Re-enter your password" class="form-control" id="confirm-pass-user" :class="formControl(v$.form.confirm_password)" :disabled="loading">
                        <div v-if="v$.form.confirm_password.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.confirm_password.$errors[0].$validator, 'Confirm password') }}</span>
                        </div>
                    </div>
                </form>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-next-reg" @click="next">Next</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue';
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapActions } from 'vuex';

export default defineComponent({
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            loading: false,
            emailExists: false,
            form: {
                church_name: null,
                website: null,
                email: null,
                first_name: null,
                last_name: null,
                password: null,
                confirm_password: null
            }
        };
    },
    emits: ['update:modelValue', 'next'],
    setup: () => ({ v$: useVuelidate() }),
    validations () {
        return {
            form: {
                church_name: { required },
                website: { required, websiteCheck: this.websiteCheck },
                email: { required, email, emailCheck: this.emailCheck },
                first_name: { required },
                last_name: { required },
                password: { required },
                confirm_password: { required, confirmPassword: this.confirmPassword }
            }
        };
    },
    methods: {
        async next() {
            this.loading = true;
            this.emailExists = false;

            await this.finduser({
                query: { email: this.form.email }
            })
            .then(result => result.total !== 0)
            .then(result => new Promise((resolve) => {
                this.v$.form.$reset();
                this.emailExists = result;
                setTimeout(() => {
                    this.v$.form.$touch();
                    resolve(result);
                }, 100);
            }));

            const isFormCorrect = await this.v$.$validate();
            if (isFormCorrect) {
                this.$emit('update:modelValue', this.form);
                this.$emit('next');
            }

            this.loading = false;
        },
        errorMessage(type, name) {
            switch(type) {
                case 'email': return `${name} is not a valid email address`;
                case 'required': return `${name} is required`;
                case 'confirmPassword': return `Invalid confirmation password`;
                case 'emailCheck': return 'Email address is already exists';
                case 'websiteCheck': return 'Invalid website url';
            }
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        confirmPassword(value) {
            return value === this.form.password;
        },
        emailCheck() {
            return !this.emailExists;
        },
        websiteCheck(url) {
            return /^(ftp|http|https):\/\/[^ "]+$/.test(url);
        },
        ...mapActions('users', { finduser: 'find' })
    }
})
</script>
