<template>
    <section class="signup-section">
        <div class="container my-container">
            <div class="signup-content">
                <div class="success-submitted-container">
                <div class="msg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114">
                    <g id="check-success" transform="translate(-190 240)">
                        <g id="Layer_44" data-name="Layer 44" transform="translate(190 -240)">
                        <path id="Path_80" data-name="Path 80" d="M59,2a57,57,0,1,0,57,57A57,57,0,0,0,59,2ZM80.147,51.647l-22.8,22.8a5.7,5.7,0,0,1-8.094,0l-11.4-11.4a5.723,5.723,0,0,1,8.094-8.094l7.353,7.41,18.753-18.81a5.723,5.723,0,0,1,8.094,8.094Z" transform="translate(-2 -2)" fill="#6c782a"/>
                        </g>
                    </g>
                    </svg>                    
                </div>
                <h4 class="title-head">Thank you for registering your church.</h4>
                    <p class="msg-normal-text">
                    We are happy to be of help to our  brothers and sisters in CHRIST.
                    <br><br>
                    Some members from your church are awaiting your confirmation in order to have full access of QuiverShare resources.
                    </p>
                    <div class="d-grid gap-2">
                    <button type="button" class="btn btn-ok" @click="doLogin">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
    props: {
        email: String,
        password: String
    },
    methods: {
        async doLogin() {
            await this.authenticate({
                strategy: 'local',
                email: this.email,
                password: this.password
            });
            this.$router.push('/newsfeed');
        },
        ...mapActions('auth', ['authenticate'])
    }
})
</script>