<template>
    <section class="signup-section">
        <div class="container my-container">
            <div class="signup-content reg">
                <div class="reg-contianer">
                <h2 class="title">Register</h2>
                <p class="user-clasification">You are registering as <span>INDIVIDUAL</span>.</p>
                <p class="steps">2 of 2</p>
                </div>

                <form class="register-form">
                    <div class="form-check form-switch">
                    <div class="row">
                        <div class="col-10">
                        <p class="label-homeschool">Do you homeschool your children ?</p>
                        </div>
                        <div class="col-2 switch-label">
                        <input v-model="updateChildrens.value" class="form-check-input" type="checkbox" id="flexSwitchCheckChecked">
                        <p class="yes">Yes</p>
                        <p class="no">No</p>
                        </div>
                    </div>
                    </div>
                    <div v-if="form.has_children" class="mb-1">
                        <label for="email-user" class="col-form-label">Homeschooling Chrildren</label>
                        <div class="row mb-2" v-for="(child, index) in form.has_children ? form.childrens : []" :key="index">
                            <div class="col-6">
                                <select v-model="form.childrens[index].grade" :class="['form-select', {
                                    'is-invalid': !v$.form.childrens.$model[index].grade,
                                    'is-valid': !!v$.form.childrens.$model[index].grade
                                }]" aria-label="Default select example" :disabled="loading">
                                    <option selected :value="null">Select Grade</option>
                                    <option v-for="(item, index) in childrenSchoolGrade" :key="index" :value="item.id">{{ item.name }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    <span>School Grade is required!</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <input v-model="form.childrens[index].no_children" type="number" placeholder="Enter no. of children" :class="['form-control', {
                                    'is-invalid': !v$.form.childrens.$model[index].no_children,
                                    'is-valid': !!v$.form.childrens.$model[index].no_children
                                }]" id="no-children" :disabled="loading">
                                <div class="invalid-feedback">
                                    <span>No. of children is required!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="form.has_children" class="d-grid gap-2">
                        <button type="button" class="btn btn-add-grade" @click="addGrade">Add Another Grade</button>
                    </div>
                    <div class="mb-1">
                        <label for="inputMembership" class="form-label">Church Membership</label>
                        <select v-model="form.church" id="inputMembership" :class="formControl(v$.form.church)" :disabled="loading || (this.request && this.request['church_id'])">
                            <option selected :value="null">Select Church</option>
                            <option v-for="(item, index) in churchs" :value="item.id" :key="index">{{ item.name }}</option>
                        </select>
                        <div v-if="v$.form.church.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.church.$errors[0].$validator, 'Church membership') }}</span>
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-next-reg" @click="next" :disabled="loading">Next</button>
                        <button type="button" class="btn btn-back-reg" @click="back" :disabled="loading">Back</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent, getCurrentInstance, computed } from 'vue';
import { useFind } from '@feathersjs/vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default defineComponent({
    props: {
        request: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            loading: false,
            form: {
                has_children: false,
                childrens: [],
                church: (this.request && this.request['church_id'])
            }
        };
    },
    emits: ['back', 'next'],
    setup() {
        const vm = getCurrentInstance();
        const { ChildrenSchoolGrade, Church }  = vm.appContext.provides.$FeathersVuex.api;
        const dataSchoolGrade = useFind({ model: ChildrenSchoolGrade, params: computed(() => {
            return {
                query: { $limit: 100 }
            }
        }), immediate: true });
        const dataChurch = useFind({ model: Church, params: computed(() => {
            return {
                query: { $limit: 100 }
            }
        }), immediate: true });
        return {
            childrenSchoolGrade: dataSchoolGrade.items,
            churchs: dataChurch.items,
            v$: useVuelidate()
        };
    },
    validations () {
        return {
            form: {
                childrens: {
                    $each: {
                        grade: { required },
                        no_children: { required },
                    }
                },
                church: { required }
            }
        };
    },
    computed: {
        validChildrends() {
            return this.form.childrens.filter(i => !i.grade || !i.no_children).length === 0;
        },
        updateChildrens() {
            const self = this;
            return {
                get value() {
                    return self.form.has_children;
                },
                set value(v) {
                    if(v) {
                        self.form.childrens.push({
                            grade: null,
                            no_children: null
                        });
                        self.form.has_children = v;
                    } else {
                        self.form.has_children = v;
                        self.form.childrens = [];
                    }
                }
            }
        }
    },
    methods: {
        addGrade() {
            this.form.childrens.push({
                grade: null,
                no_children: null
            });
        },
        async next() {
            const isFormCorrect = await this.v$.$validate();
            if(isFormCorrect && this.validChildrends) {
                const extended = {
                    church_id: this.form.church,
                    childrens: this.form.childrens
                };
                this.$emit('next', extended);
            }
        },
        back() {
            this.$emit('back');
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form && form.$dirty && form.$invalid,
                'is-valid': form && form.$dirty && !form.$invalid
            };
        },
        errorMessage(type, name) {
            switch(type) {
                case 'required': return `${name} is required`;
            }
        },
    }
})
</script>



